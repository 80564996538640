import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import { Jumbotron, Button } from "reactstrap"
import SEO from "../components/seo"
import { StaticQuery, graphql } from "gatsby"

const IndexPage = ({ children }) => (
  <StaticQuery
    query={graphql`
      query KeywordsQuery {
        site {
          siteMetadata {
            keywords
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <SEO title="Startseite" keywords={data.site.siteMetadata.keywords} />
        <Jumbotron className="text-center pt-4 bg-white">
          <h1 className="jumbotron-heading">Willkommen bei Waerder & Lorenz</h1>
          <h2 className="my-2">
            Steuerberater
            <span className="d-lg-none"> Partnerschafts-gesellschaft </span>
            <span className="d-none d-lg-inline">
              {" "}
              Partnerschaftsgesellschaft{" "}
            </span>
            mbB
          </h2>
          <p className="">
            Die Sozietät wurde im April 2008 gegründet und ging aus den am Ort
            alteingesessenen Einzelkanzleien der beiden Partner hervor. Die
            Kanzlei befindet sich in einer historischen Villa im Ortskern des
            Ortsteils Hilgen von Burscheid. Angenehme Arbeitsatmosphäre und
            modernste Technik erlaubt es uns hier, das gesamte Spektrum der
            steuerlichen und betriebswirtschaftlichen Beratung für unsere
            Mandanten abzudecken.
          </p>

          <p>
            <Link to="/kontakt">
              <Button color="primary" className="my-2">
                Kontaktieren Sie uns
              </Button>
            </Link>
            <Button color="secondary" className="my-2 mx-2">
              <Link to="/ueberuns" className="text-white">
                Mehr über uns
              </Link>
            </Button>
          </p>
        </Jumbotron>
      </Layout>
    )}
  />
)

export default IndexPage
